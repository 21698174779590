'use client';

import * as React from 'react';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import { cn } from '../../../lib/utils';
import { cva, VariantProps } from 'class-variance-authority';

const Tabs = TabsPrimitive.Root;

const tabsListVariants = cva(
  'inline-flex h-10 items-center justify-start rounded-md gap-1',
  {
    variants: {
      variant: {
        none: 'text-muted-foreground gap-0',
        default: 'text-muted-foreground gap-1',
        outlined: 'border border-gray-300 bg-white text-gray-700',
        filled: 'inline-flex bg-gray-100 p-1 rounded-lg h-12 mb-4',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List> &
    VariantProps<typeof tabsListVariants>
>(({ className, variant, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(tabsListVariants({ variant }), className)}
    {...props}
  />
));

TabsList.displayName = TabsPrimitive.List.displayName;

export default TabsList;

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger> & {
    variant?:
      | 'default'
      | 'providerCardVariant'
      | 'providerBlue'
      | 'white'
      | 'countryImage'
      | 'sidebar';
  }
>(({ className, variant = 'default', ...props }, ref) => {
  const variantClasses = {
    default: '',
    providerCardVariant: cn(
      'inline-flex items-center justify-center rounded-t-lg px-3 py-1.5 text-sm font-medium transition-all',
      'w-16',
      'bg-[#F1F2F4] text-muted-foreground ring-offset-background',
      'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
      'data-[state=active]:bg-white data-[state=active]:text-foreground data-[state=active]:shadow-sm',
      'data-[state=active]:border-x data-[state=active]:border-t data-[state=active]:border-b-white data-[state=active]:border-[#93adc3]',
      'data-[state=active]:pb-[0.46rem]'
    ),
    providerBlue: cn(
      'inline-flex items-center justify-center rounded-t-lg px-2 md:px-6 py-1 text-sm md:text-base font-normal transition-all',
      'bg-white text-gray-600 border-b border-b-dividerGray',
      'hover:bg-gray-300 hover:text-gray-800',
      'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
      'data-[state=active]:bg-blue-100 data-[state=active]:text-black data-[state=active]:font-normal',
      'data-[state=active]:border-b-transparent -mb-px', // there is no way to do this without decrease the bottom margin
      'w-auto min-w-0 truncate overflow-hidden whitespace-nowrap text-ellipsis'
    ),
    white: cn(
      'inline-flex items-center justify-center px-4 py-2 text-sm font-medium transition-all',
      'text-gray-500 rounded-lg',
      'hover:bg-gray-200 hover:text-gray-800',
      'data-[state=active]:bg-white data-[state=active]:text-black data-[state=active]:shadow-[0_0_4px_rgb(0,0,0,0.16)]',
      'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2'
    ),
    sidebar: cn(
      'flex items-center gap-2 px-4 py-3 text-sm font-normal rounded-lg transition-colors',
      'justify-start w-full text-left',
      'hover:bg-gray-100',
      'data-[state=active]:bg-gray-200 data-[state=active]:text-black'
    ),
    countryImage: cn('p-0 m-0'),
  };

  return (
    <TabsPrimitive.Trigger
      ref={ref}
      className={cn(variantClasses[variant], className)}
      {...props}
    />
  );
});
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      '-mt-0.5 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
      className
    )}
    {...props}
  />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { Tabs, TabsList, TabsTrigger, TabsContent };
