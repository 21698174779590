import * as React from 'react';
import { SvgIconProps } from '@mui/material';
import { cn } from './lib/utils';
import getSpacingClassNames, {
  SpacingPropsType,
} from './helpers/getSpacingClassNames';
import stripSpacingProps from './helpers/stripSpacingProps';
import * as IconComponents from './components';
import { red } from '@mui/material/colors';

export const iconNames = Object.keys(IconComponents);
export type IconName = keyof typeof IconComponents;
export { IconSize };

export type IconProps = SvgIconProps & {
  name: IconName;
  size?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 'fullWidth';
  iconColor?: IconColor;
} & SpacingPropsType;

export type IconColor =
  | 'default'
  | 'inherit'
  | 'light'
  | 'primary'
  | 'darkBlue'
  | 'blueGray'
  | 'dark'
  | 'success'
  | 'gray'
  | 'yellow'
  | 'darkYellow'
  | 'red'
  | 'secondaryText';

export const colorClassName = {
  default: 'text-[#12A3FD]',
  inherit: '',
  light: 'text-[#ffffff]',
  primary: 'text-[#0283D1]',
  darkBlue: 'text-[#014E7E]',
  yellow: 'text-[#FFD324]',
  blueGray: 'text-[#799AB5]',
  dark: 'text-[#000000]',
  gray: 'text-gray-100',
  success: 'text-[#00bd52]',
  darkYellow: 'text-[#D6AB00]',
  red: 'text-[#AA0829]',
  secondaryText: 'text-[#525A6B]',
};

const IconSize = {
  1: 0.625,
  2: 0.75,
  3: 0.875,
  4: 1,
  5: 1.25,
  6: 1.5,
  7: 1.75,
  8: 2,
  9: 2.5,
  10: 3,
  11: 3.5,
  12: 4,
  13: 5,
  14: 6,
  fullWidth: '100%',
};

export const Icon = ({
  name,
  size = 8,
  iconColor = 'inherit',
  className,
  ...otherProps
}: IconProps): JSX.Element => {
  const Comp = IconComponents[name] as React.ComponentType<SvgIconProps>;
  const spacingClassNames = getSpacingClassNames(otherProps);
  const htmlElementProps = stripSpacingProps(otherProps);

  return (
    <Comp
      {...htmlElementProps}
      style={{
        fill: 'currentColor',
        width:
          typeof IconSize[size] === 'number'
            ? `${IconSize[size]}rem`
            : IconSize[size],
        height:
          typeof IconSize[size] === 'number'
            ? `${IconSize[size]}rem`
            : IconSize[size],
      }}
      className={cn(
        'inline-block',
        spacingClassNames,
        colorClassName[iconColor],
        className
      )}
    />
  );
};
