export const ParkingRateIcon = (
  props: Omit<React.ComponentProps<'svg'>, 'children'>
) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M14.25 13C13.4167 13 12.7083 12.7083 12.125 12.125C11.5417 11.5417 11.25 10.8333 11.25 10C11.25 9.16667 11.5417 8.45833 12.125 7.875C12.7083 7.29167 13.4167 7 14.25 7C15.0833 7 15.7917 7.29167 16.375 7.875C16.9583 8.45833 17.25 9.16667 17.25 10C17.25 10.8333 16.9583 11.5417 16.375 12.125C15.7917 12.7083 15.0833 13 14.25 13ZM7.25 16C6.7 16 6.22917 15.8042 5.8375 15.4125C5.44583 15.0208 5.25 14.55 5.25 14V6C5.25 5.45 5.44583 4.97917 5.8375 4.5875C6.22917 4.19583 6.7 4 7.25 4H21.25C21.8 4 22.2708 4.19583 22.6625 4.5875C23.0542 4.97917 23.25 5.45 23.25 6V14C23.25 14.55 23.0542 15.0208 22.6625 15.4125C22.2708 15.8042 21.8 16 21.25 16H7.25ZM9.25 14H19.25C19.25 13.45 19.4458 12.9792 19.8375 12.5875C20.2292 12.1958 20.7 12 21.25 12V8C20.7 8 20.2292 7.80417 19.8375 7.4125C19.4458 7.02083 19.25 6.55 19.25 6H9.25C9.25 6.55 9.05417 7.02083 8.6625 7.4125C8.27083 7.80417 7.8 8 7.25 8V12C7.8 12 8.27083 12.1958 8.6625 12.5875C9.05417 12.9792 9.25 13.45 9.25 14ZM19.25 20H3.25C2.7 20 2.22917 19.8042 1.8375 19.4125C1.44583 19.0208 1.25 18.55 1.25 18V8C1.25 7.71667 1.34583 7.47917 1.5375 7.2875C1.72917 7.09583 1.96667 7 2.25 7C2.53333 7 2.77083 7.09583 2.9625 7.2875C3.15417 7.47917 3.25 7.71667 3.25 8V18H19.25C19.5333 18 19.7708 18.0958 19.9625 18.2875C20.1542 18.4792 20.25 18.7167 20.25 19C20.25 19.2833 20.1542 19.5208 19.9625 19.7125C19.7708 19.9042 19.5333 20 19.25 20Z"
      fill="currentColor"
    />
  </svg>
);
