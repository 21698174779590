export const CoveredParkingIcon = (
  props: Omit<React.ComponentProps<'svg'>, 'children'>
) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9999 13.5201C13.1149 14.1585 12.0356 14.4696 10.9465 14.4001H8.4132V18.6668H5.87987V6.66676H11.1065C12.1467 6.61162 13.171 6.93885 13.9865 7.58676C14.3671 7.9552 14.6612 8.40334 14.848 8.899C15.0347 9.39465 15.1094 9.9255 15.0665 10.4534C15.1351 11.0121 15.0746 11.5791 14.8897 12.1108C14.7048 12.6424 14.4004 13.1246 13.9999 13.5201ZM11.9999 9.2001C11.6146 8.91759 11.1437 8.77634 10.6665 8.8001H8.4132V12.3334H10.6665C11.1482 12.3589 11.6225 12.2072 11.9999 11.9068C12.1686 11.7304 12.2985 11.5206 12.3812 11.291C12.4638 11.0613 12.4975 10.8169 12.4799 10.5734C12.5109 10.323 12.4837 10.0688 12.4005 9.83063C12.3172 9.59243 12.1801 9.37666 11.9999 9.2001ZM19.2132 5.61343C19.2862 5.45413 19.2936 5.27248 19.2337 5.10779C19.1738 4.9431 19.0515 4.80862 18.8932 4.73343L9.99987 0.600098L1.0532 4.73343C0.973536 4.7702 0.901889 4.8223 0.842357 4.88676C0.782824 4.95122 0.73657 5.02677 0.706236 5.10911C0.675902 5.19144 0.662082 5.27895 0.665566 5.36662C0.669049 5.4543 0.689767 5.54043 0.726537 5.6201C0.763308 5.69977 0.815409 5.77141 0.879868 5.83094C0.944328 5.89048 1.01988 5.93673 1.10222 5.96707C1.18455 5.9974 1.27205 6.01122 1.35973 6.00774C1.4474 6.00425 1.53354 5.98353 1.6132 5.94676L9.99987 2.06676L18.3865 5.93343C18.5458 6.00646 18.7275 6.01382 18.8922 5.95393C19.0569 5.89405 19.1914 5.77172 19.2665 5.61343H19.2132Z"
      fill="currentColor"
    />
  </svg>
);
