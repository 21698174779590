export const SafetyIcon = (props: Omit<React.ComponentProps<'svg'>, 'children'>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M9.2487 12.5001H10.7487C10.8737 12.5001 10.9813 12.448 11.0716 12.3438C11.1619 12.2397 11.1931 12.1251 11.1654 12.0001L10.7695 9.81258C11.0473 9.67369 11.2661 9.4723 11.4258 9.20842C11.5855 8.94453 11.6654 8.65286 11.6654 8.33341C11.6654 7.87508 11.5022 7.48272 11.1758 7.15633C10.8494 6.82994 10.457 6.66675 9.9987 6.66675C9.54036 6.66675 9.148 6.82994 8.82161 7.15633C8.49523 7.48272 8.33203 7.87508 8.33203 8.33341C8.33203 8.65286 8.41189 8.94453 8.57161 9.20842C8.73134 9.4723 8.95009 9.67369 9.22786 9.81258L8.83203 12.0001C8.80425 12.1251 8.8355 12.2397 8.92578 12.3438C9.01606 12.448 9.1237 12.5001 9.2487 12.5001ZM9.9987 18.2501C9.90148 18.2501 9.8112 18.2431 9.72786 18.2292C9.64453 18.2154 9.5612 18.1945 9.47786 18.1667C7.60286 17.5417 6.10981 16.3855 4.9987 14.698C3.88759 13.0105 3.33203 11.1945 3.33203 9.25008V5.31258C3.33203 4.96536 3.43273 4.65286 3.63411 4.37508C3.8355 4.0973 4.09592 3.89591 4.41536 3.77091L9.41536 1.89591C9.60981 1.82647 9.80425 1.79175 9.9987 1.79175C10.1931 1.79175 10.3876 1.82647 10.582 1.89591L15.582 3.77091C15.9015 3.89591 16.1619 4.0973 16.3633 4.37508C16.5647 4.65286 16.6654 4.96536 16.6654 5.31258V9.25008C16.6654 11.1945 16.1098 13.0105 14.9987 14.698C13.8876 16.3855 12.3945 17.5417 10.5195 18.1667C10.4362 18.1945 10.3529 18.2154 10.2695 18.2292C10.1862 18.2431 10.0959 18.2501 9.9987 18.2501ZM9.9987 16.5834C11.4431 16.1251 12.6376 15.2084 13.582 13.8334C14.5265 12.4584 14.9987 10.9306 14.9987 9.25008V5.31258L9.9987 3.43758L4.9987 5.31258V9.25008C4.9987 10.9306 5.47092 12.4584 6.41536 13.8334C7.35981 15.2084 8.55425 16.1251 9.9987 16.5834Z"
      fill="currentColor"
    />
  </svg>
);
