export const HandoverKeysIcon = (
  props: Omit<React.ComponentProps<'svg'>, 'children'>
) => (
  <svg
    {...props}
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1760_16871)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.4142 11.9349C42.1352 11.2993 42.109 10.5811 42.3409 9.92676C42.5728 9.27246 43.0453 8.7314 43.662 8.41399C43.9744 8.26237 44.3138 8.17443 44.6605 8.15525C45.0071 8.13608 45.3541 8.18607 45.6813 8.30231C46.0085 8.41855 46.3093 8.59873 46.5664 8.83238C46.8234 9.06603 47.0315 9.34849 47.1786 9.66335C47.4576 10.299 47.4838 11.0172 47.2519 11.6715C47.02 12.3258 46.5475 12.8669 45.9308 13.1843C45.6209 13.3492 45.2801 13.4477 44.9301 13.4736C44.5801 13.4994 44.2286 13.4521 43.8978 13.3346C43.5671 13.2171 43.2644 13.032 43.009 12.791C42.7535 12.5501 42.551 12.2585 42.4142 11.9349ZM32.8853 10.0041C33.1729 8.40935 33.838 6.90683 34.8248 5.62236C35.8116 4.33789 37.0915 3.30887 38.5572 2.62148C39.7536 2.04489 41.0531 1.71389 42.3792 1.64799C43.7053 1.58209 45.0311 1.78262 46.2786 2.23777C47.5261 2.69292 48.6699 3.39347 49.6428 4.29815C50.6156 5.20282 51.3978 6.29331 51.9431 7.50536C53.07 9.94761 53.1899 12.7362 52.2768 15.2664C51.3636 17.7966 49.4911 19.8643 47.0652 21.0212C45.604 21.7262 43.993 22.0637 42.372 22.0043C40.7511 21.9449 39.169 21.4905 37.7632 20.6805L36.6288 21.2484L33.4525 20.5669L31.8643 23.9743L27.6671 23.1792L25.7386 26.9273L17.1172 23.9743L20.1801 16.0238L32.8853 10.0041ZM34.36 17.3867L36.2885 17.841L38.1035 16.9324C39.1157 17.8539 40.3783 18.4538 41.7314 18.656C43.0845 18.8582 44.4671 18.6536 45.7039 18.0682C47.3854 17.2813 48.6873 15.8598 49.3251 14.1146C49.9628 12.3693 49.8844 10.4423 49.1071 8.75473C48.7298 7.92572 48.1903 7.18088 47.5206 6.56423C46.8508 5.94758 46.0643 5.47165 45.2077 5.16457C44.351 4.85749 43.4416 4.7255 42.5331 4.77641C41.6246 4.82732 40.7356 5.06008 39.9185 5.46095C38.6824 6.04562 37.6463 6.98265 36.94 8.15455C36.2337 9.32645 35.8886 10.6811 35.9481 12.0485L32.545 13.6386L22.6757 18.2953L21.3144 21.9298L24.2639 22.952L25.9655 19.6583L30.1627 20.3397L31.7509 16.8188L34.36 17.3867Z"
        fill="#12A3FD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2096 42.4436C13.344 41.0807 14.7053 39.6042 16.18 38.0141C18.8233 35.2214 22.4384 33.554 26.2761 33.3574H44.8802C46.3978 33.3836 47.8646 33.9095 49.0538 34.8539C50.243 35.7982 51.0885 37.1084 51.4597 38.582C54.1822 35.6289 56.5644 33.0166 58.6064 30.6315C59.1649 29.908 59.8604 29.3018 60.653 28.8474C61.4456 28.3931 62.3198 28.0995 63.2258 27.9835C64.1317 27.8675 65.0516 27.9313 65.9329 28.1713C66.8142 28.4112 67.6397 28.8227 68.3621 29.3821C69.0843 29.8935 69.6898 30.5525 70.1387 31.3156C70.5876 32.0787 70.8696 32.9285 70.9661 33.8088C71.0626 34.6891 70.9714 35.5799 70.6985 36.4223C70.4256 37.2647 69.9773 38.0395 69.3831 38.6955C68.9293 39.2634 63.8246 45.7374 61.7827 48.1226C59.7407 50.5077 58.0392 52.325 56.3376 54.0287C52.5941 57.6632 49.3043 59.7076 46.4683 59.7076H21.9654L22.4192 60.3891C22.6972 60.8878 22.7697 61.4756 22.6214 62.0271C22.4731 62.5787 22.1156 63.0505 21.6251 63.3421L13.344 68.1124C12.8418 68.3713 12.2611 68.4333 11.7157 68.2862C11.1702 68.1392 10.6991 67.7937 10.3946 67.3173L-0.722461 47.7818C-1.00046 47.283 -1.07303 46.6953 -0.924715 46.1438C-0.776402 45.5922 -0.418924 45.1204 0.071615 44.8288L8.35269 40.0585C8.85493 39.7996 9.43557 39.7376 9.98105 39.8846C10.5265 40.0317 10.9976 40.3772 11.3021 40.8535L12.2096 42.4436Z"
        fill="#014E7E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9377 65.5618L20.2241 61.7645L10.2982 43.7275L4.01172 47.6435L13.9377 65.5618Z"
        fill="#014E7E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8281 45.3903L20.894 56.782H46.5912C48.3558 56.782 50.8924 55.1207 54.0908 51.9168C55.6348 50.2555 57.2891 48.3569 59.164 46.1022C61.0389 43.8476 66.0019 36.9651 66.5533 36.3718C66.8573 36.0459 67.0879 35.6495 67.2275 35.2129C67.3671 34.7764 67.412 34.3112 67.3588 33.8531C67.3056 33.395 67.1556 32.956 66.9204 32.5699C66.6853 32.1837 66.3711 31.8606 66.0019 31.6252C65.6186 31.2926 65.1753 31.0491 64.7 30.9101C64.2248 30.7711 63.728 30.7396 63.2409 30.8177C62.7538 30.8958 62.287 31.0816 61.87 31.3636C61.4531 31.6456 61.095 32.0175 60.8184 32.4559C57.9509 36.0158 54.4216 40.169 50.341 44.7969C49.71 45.6642 48.9077 46.3697 47.9921 46.8622C47.0765 47.3548 46.0706 47.6221 45.0471 47.6449H34.68C34.2705 47.6449 33.8778 47.4698 33.5882 47.1583C33.2987 46.8467 33.136 46.4242 33.136 45.9836C33.136 45.543 33.2987 45.1204 33.5882 44.8089C33.8778 44.4973 34.2705 44.3223 34.68 44.3223H45.0471C45.5516 44.3363 46.0524 44.226 46.5112 43.9997C46.97 43.7735 47.3746 43.4373 47.694 43.017C47.694 42.8983 47.8043 42.8983 47.8043 42.7796C48.3343 42.0774 48.6087 41.1916 48.5764 40.2877C48.5677 39.252 48.1815 38.2614 47.5008 37.529C46.8201 36.7966 45.8994 36.3811 44.9368 36.3718H26.9598C24.0721 36.5732 21.3583 37.9274 19.3499 40.169C17.5853 42.0677 16.0413 43.8476 14.8281 45.3903Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1760_16871">
        <rect width="70" height="70" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
