export const ElectricChargerIcon = (
  props: Omit<React.ComponentProps<'svg'>, 'children'>
) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M8.79099 15.1666L13.1035 9.99995H9.77016L10.3743 5.27078L6.52016 10.8333H9.41599L8.79099 15.1666ZM7.49932 12.5H4.91599C4.58266 12.5 4.33613 12.3506 4.17641 12.052C4.01668 11.7534 4.03405 11.4652 4.22849 11.1875L10.4577 2.22912C10.5965 2.03467 10.7771 1.89926 10.9993 1.82287C11.2215 1.74648 11.4507 1.74995 11.6868 1.83328C11.9229 1.91662 12.0965 2.06245 12.2077 2.27078C12.3188 2.47912 12.3604 2.70134 12.3327 2.93745L11.666 8.33328H14.8952C15.2563 8.33328 15.5097 8.49301 15.6556 8.81245C15.8014 9.1319 15.7563 9.43051 15.5202 9.70828L8.66599 17.9166C8.51321 18.0972 8.32571 18.2152 8.10349 18.2708C7.88127 18.3263 7.66599 18.3055 7.45766 18.2083C7.24932 18.1111 7.08613 17.9618 6.96807 17.7604C6.85002 17.559 6.80488 17.3402 6.83266 17.1041L7.49932 12.5Z"
      fill="currentColor"
    />
  </svg>
);
