export const IdealIcon = (props: Omit<React.ComponentProps<'svg'>, 'children'>) => (
  <svg
    {...props}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6865_8598)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.07031 2.62158V21.6432H14.7499L19.308 20.4458L22.4083 17.8522L23.9295 13.7706V9.05725L21.6583 5.26617L17.6971 3.11585L13.8837 2.80502L1.07031 2.62158Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.47711 2.94268V21.0573H12.8856C19.7729 21.0573 23.5229 17.9592 23.5229 11.9771C23.5229 5.82675 19.4613 2.94268 12.8856 2.94268H1.47711ZM12.8856 2C22.9894 2 24.5 8.25223 24.5 11.9771C24.5 18.4382 20.375 22 12.8856 22H0.5V2H12.8856Z"
        fill="#0A0B09"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.04492 19.6144H6.69985V13.3418H3.04492V19.6144Z"
        fill="#0A0B09"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.13991 10.1877C7.13991 10.4754 7.08117 10.7603 6.96703 11.0262C6.8529 11.292 6.68561 11.5335 6.47471 11.737C6.26382 11.9405 6.01345 12.1018 5.73791 12.212C5.46236 12.3221 5.16703 12.3787 4.86878 12.3787C4.57053 12.3787 4.27521 12.3221 3.99966 12.212C3.72411 12.1018 3.47375 11.9405 3.26285 11.737C3.05196 11.5335 2.88467 11.292 2.77054 11.0262C2.6564 10.7603 2.59766 10.4754 2.59766 10.1877C2.59766 9.60655 2.83693 9.04924 3.26285 8.63834C3.68877 8.22743 4.26644 7.99658 4.86878 7.99658C5.47112 7.99658 6.04879 8.22743 6.47471 8.63834C6.90063 9.04924 7.13991 9.60655 7.13991 10.1877Z"
        fill="#0A0B09"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.51172 9.16943V11.2127H9.16137C9.61031 11.2127 10.0276 11.0854 10.0276 10.1733C10.0276 9.28153 9.56277 9.16943 9.16137 9.16943H8.51172ZM19.3656 8.15032H20.4166V11.2127H21.9747C21.5469 5.6586 17.0205 4.45605 12.9008 4.45605H8.51172V8.15032H9.16137C10.3445 8.15032 11.0839 8.92485 11.0839 10.1733C11.0839 11.4573 10.3656 12.2268 9.16137 12.2268H8.51172V19.6204H12.9008C19.5927 19.6204 21.9219 16.6242 22.0012 12.2268H19.3603V8.15032H19.3656ZM16.4343 10.4229H17.1948L16.8462 9.27134H16.7828L16.4343 10.4229ZM14.7916 12.2268L16.0645 8.15032H17.5593L18.8321 12.2268H17.7388L17.5012 11.4369H16.1226L15.885 12.2268H14.7916ZM14.2846 11.2127V12.2268H11.6807V8.15032H14.2001V9.16433H12.7318V9.63822H14.1209V10.6522H12.7318V11.2127H14.2846Z"
        fill="#E60073"
      />
    </g>
    <defs>
      <clipPath id="clip0_6865_8598">
        <rect width="24" height="24" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
