export const BookingFaqIcon = (
  props: Omit<React.ComponentProps<'svg'>, 'children'>
) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M4.9987 16.6666V17.4999H13.332V16.6666H4.9987ZM12.457 10.9999L16.582 6.87492C16.7348 6.72214 16.9293 6.64575 17.1654 6.64575C17.4015 6.64575 17.5959 6.72214 17.7487 6.87492C17.9015 7.0277 17.9779 7.22214 17.9779 7.45825C17.9779 7.69436 17.9015 7.88881 17.7487 8.04159L13.0404 12.7499C12.8737 12.9166 12.6793 12.9999 12.457 12.9999C12.2348 12.9999 12.0404 12.9166 11.8737 12.7499L9.4987 10.3749C9.34592 10.2221 9.26953 10.0277 9.26953 9.79158C9.26953 9.55547 9.34592 9.36103 9.4987 9.20825C9.65148 9.05547 9.84592 8.97908 10.082 8.97908C10.3181 8.97908 10.5126 9.05547 10.6654 9.20825L12.457 10.9999ZM4.9987 3.33325H13.332V2.49992H4.9987V3.33325ZM4.9987 19.1666C4.54036 19.1666 4.148 19.0034 3.82161 18.677C3.49523 18.3506 3.33203 17.9583 3.33203 17.4999V2.49992C3.33203 2.04159 3.49523 1.64922 3.82161 1.32284C4.148 0.996446 4.54036 0.833252 4.9987 0.833252H13.332C13.7904 0.833252 14.1827 0.996446 14.5091 1.32284C14.8355 1.64922 14.9987 2.04159 14.9987 2.49992V4.99992C14.9987 5.23603 14.9188 5.43395 14.7591 5.59367C14.5994 5.75339 14.4015 5.83325 14.1654 5.83325C13.9293 5.83325 13.7313 5.75339 13.5716 5.59367C13.4119 5.43395 13.332 5.23603 13.332 4.99992H4.9987V14.9999H13.332C13.332 14.7638 13.4119 14.5659 13.5716 14.4062C13.7313 14.2464 13.9293 14.1666 14.1654 14.1666C14.4015 14.1666 14.5994 14.2464 14.7591 14.4062C14.9188 14.5659 14.9987 14.7638 14.9987 14.9999V17.4999C14.9987 17.9583 14.8355 18.3506 14.5091 18.677C14.1827 19.0034 13.7904 19.1666 13.332 19.1666H4.9987Z"
      fill="currentColor"
    />
  </svg>
);
