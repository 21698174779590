'use client';
import * as React from 'react';
import { DayPicker } from 'react-day-picker';
import { Icon } from '@icons/icons';
import { cn } from '../../lib/utils';
import { buttonVariants } from './button';

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
  variant?: 'default' | 'overlay';
  title?: string;
};

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  variant = 'default',
  title,
  ...props
}: CalendarProps) {
  const overlayClasses =
    variant === 'overlay'
      ? {
          months: 'flex flex-col items-center space-y-4 w-full',
          caption:
            'flex items-center justify-center bg-gray-100 rounded-lg p-2 w-full',
          caption_label: 'text-lg font-regular text-gray-700',
          nav: 'absolute left-0 right-0 flex items-center justify-between px-10',
          nav_button:
            'text-white hover:text-gray-300 rounded-full bg-arrowBtnBackground w-8 h-8',
          nav_button_previous: '',
          nav_button_next: '',
          table: 'border-collapse w-full',
          head_row: 'flex justify-between w-full bg-gray-100 pt-1 text-sm',
          head_cell:
            'w-8 h-8 flex justify-center items-center text-gray-500 font-normal mx-auto',
          row: 'flex justify-between w-full bg-gray-100 pt-1 text-sm',
          cell: 'w-8 h-8 flex justify-center items-center px-6 py-2',
          day: ' w-6 h-6 p-1 flex font-medium text-sm justify-center items-center rounded-lg hover:bg-blue-100 aria-selected:bg-blue-400 aria-selected:text-white',
          day_selected: 'bg-blue-500 text-white',
          day_today: 'border border-blue-500',
          day_range_middle: 'bg-blue-200 text-blue-900 rounded-none',
        }
      : {};
  return (
    <>
      {title && <div className="text-left font-normal text-xl mb-4">{title}</div>}

      <DayPicker
        showOutsideDays={showOutsideDays}
        classNames={{
          months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
          month: 'space-y-4 w-full md:w-sm',
          caption: 'flex justify-center pt-1 relative items-center',
          caption_label: 'text-sm font-medium',
          nav: 'space-x-1 flex items-center',
          nav_button: cn(
            buttonVariants({ variant: 'outline' }),
            'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100'
          ),
          nav_button_previous: 'absolute left-1',
          nav_button_next: 'absolute right-1',
          table: 'w-full border-collapse space-y-1',
          head_row: 'flex',
          head_cell:
            'text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]',
          row: 'flex w-full mt-2',
          cell: 'h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
          day: cn(
            buttonVariants({ variant: 'ghost' }),
            'h-9 w-9 p-0 font-normal aria-selected:opacity-100'
          ),
          day_range_end: 'day-range-end',
          day_selected:
            'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
          day_today: 'bg-accent text-accent-foreground',
          day_outside:
            'day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
          day_disabled: 'text-muted-foreground opacity-50',
          day_range_middle:
            'aria-selected:bg-accent aria-selected:text-accent-foreground',
          day_hidden: 'invisible',

          ...overlayClasses,
          ...classNames,
        }}
        components={{
          IconLeft: ({ ...props }) => (
            <Icon name="KeyboardArrowLeftRoundedIcon" size={7} />
          ),
          IconRight: ({ ...props }) => (
            <Icon name="KeyboardArrowRightRoundedIcon" size={7} />
          ),
        }}
        {...props}
      />
    </>
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
