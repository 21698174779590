export const SaveIcon = (props: Omit<React.ComponentProps<'svg'>, 'children'>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 12.625L13.275 13.4C13.4583 13.5167 13.6375 13.5083 13.8125 13.375C13.9875 13.2417 14.05 13.0667 14 12.85L13.675 11.4L14.775 10.45C14.9417 10.3 14.9958 10.125 14.9375 9.925C14.8792 9.725 14.7333 9.60833 14.5 9.575L13.05 9.45L12.45 8.075C12.3667 7.875 12.2167 7.775 12 7.775C11.7833 7.775 11.6333 7.875 11.55 8.075L10.95 9.45L9.5 9.575C9.26667 9.60833 9.12083 9.725 9.0625 9.925C9.00417 10.125 9.05833 10.3 9.225 10.45L10.325 11.4L10 12.85C9.95 13.0667 10.0125 13.2417 10.1875 13.375C10.3625 13.5083 10.5417 13.5167 10.725 13.4L12 12.625ZM12 18L7.8 19.8C7.13333 20.0833 6.5 20.0292 5.9 19.6375C5.3 19.2458 5 18.6917 5 17.975V5C5 4.45 5.19583 3.97917 5.5875 3.5875C5.97917 3.19583 6.45 3 7 3H17C17.55 3 18.0208 3.19583 18.4125 3.5875C18.8042 3.97917 19 4.45 19 5V17.975C19 18.6917 18.7 19.2458 18.1 19.6375C17.5 20.0292 16.8667 20.0833 16.2 19.8L12 18ZM12 15.8L17 17.95V5H7V17.95L12 15.8Z"
      fill="currentColor"
    />
  </svg>
);
