import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';

import { Icon } from '@icons/icons';
import { cn } from '../../../lib/utils';

const Breadcrumb = React.forwardRef<
  HTMLElement,
  React.ComponentPropsWithoutRef<'nav'> & {
    separator?: React.ReactNode;
  }
>(({ ...props }, ref) => <nav ref={ref} aria-label="breadcrumb" {...props} />);
Breadcrumb.displayName = 'Breadcrumb';

const BreadcrumbList = React.forwardRef<
  HTMLOListElement,
  React.ComponentPropsWithoutRef<'ol'>
>(({ className, ...props }, ref) => (
  <ol
    ref={ref}
    className={cn(
      'flex flex-wrap items-center gap-1.5 break-words text-sm text-muted-foreground sm:gap-2.5',
      className
    )}
    {...props}
  />
));
BreadcrumbList.displayName = 'BreadcrumbList';

const BreadcrumbItem = React.forwardRef<
  HTMLLIElement,
  React.ComponentPropsWithoutRef<'li'>
>(({ className, ...props }, ref) => (
  <li
    ref={ref}
    className={cn('inline-flex items-center gap-1.5', className)}
    {...props}
  />
));
BreadcrumbItem.displayName = 'BreadcrumbItem';

const BreadcrumbLink = React.forwardRef<
  HTMLAnchorElement,
  React.ComponentPropsWithoutRef<'a'> & {
    asChild?: boolean;
  }
>(({ asChild, className, ...props }, ref) => {
  const Comp = asChild ? Slot : 'a';

  return (
    <Comp
      ref={ref}
      className={cn('transition-colors hover:text-foreground', className)}
      {...props}
    />
  );
});
BreadcrumbLink.displayName = 'BreadcrumbLink';

const BreadcrumbPage = React.forwardRef<
  HTMLSpanElement,
  React.ComponentPropsWithoutRef<'span'>
>(({ className, ...props }, ref) => (
  <span
    ref={ref}
    role="link"
    aria-disabled="true"
    aria-current="page"
    className={cn('font-normal text-foreground', className)}
    {...props}
  />
));
BreadcrumbPage.displayName = 'BreadcrumbPage';

const BreadcrumbSeparator = ({
  children,
  className,
  variant = 'inherit', // Default color
  ...props
}: React.ComponentProps<'li'> & { variant?: 'inherit' | 'light' }) => (
  <li
    role="presentation"
    aria-hidden="true"
    className={cn('[&>svg]:size-4', className)}
    {...props}
  >
    {children ?? (
      <Icon name="KeyboardArrowRightRoundedIcon" size={5} iconColor={variant} />
    )}
  </li>
);

BreadcrumbSeparator.displayName = 'BreadcrumbSeparator';

interface BreadcrumbEllipsisProps extends React.ComponentProps<'span'> {
  variant?: 'default' | 'white';
}

const BreadcrumbEllipsis: React.FC<BreadcrumbEllipsisProps> = ({
  className,
  variant = 'default',
  ...props
}) => {
  return (
    <span
      role="presentation"
      aria-hidden="true"
      className={cn('flex h-9 w-9 items-center justify-center', className)}
      {...props}
    >
      <Icon
        name="MoreHorizRoundedIcon"
        size={4}
        iconColor={variant === 'white' ? 'light' : 'inherit'}
      />
      <span className="sr-only">More</span>
    </span>
  );
};

BreadcrumbEllipsis.displayName = 'BreadcrumbEllipsis';

export default BreadcrumbEllipsis;

export {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbPage,
  BreadcrumbSeparator,
  BreadcrumbEllipsis,
};
