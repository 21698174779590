export const CompareIcon = (
  props: Omit<React.ComponentProps<'svg'>, 'children'>
) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
  >
    <g clipPath="url(#clip0_6170_9209)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.0769 5.97559C28.8302 5.97592 24.6451 6.91747 20.8709 8.72169C17.0967 10.5259 13.8427 13.1405 11.3805 16.3472C8.91825 19.554 7.31918 23.26 6.71667 27.156C6.11416 31.052 6.52568 35.0251 7.91689 38.7437C9.30811 42.4624 11.6387 45.8187 14.7142 48.5328C17.7896 51.2469 21.5209 53.24 25.5965 54.3459C29.6721 55.4517 33.974 55.6382 38.1433 54.8898C42.3125 54.1414 46.2282 52.4798 49.5636 50.0436L61.0059 60.6483C61.5968 61.1772 62.3882 61.4699 63.2098 61.4633C64.0313 61.4567 64.8171 61.1513 65.398 60.6129C65.9789 60.0745 66.3084 59.3462 66.3156 58.5848C66.3227 57.8235 66.0069 57.09 65.4362 56.5423L53.9939 45.9376C57.0894 42.2981 59.0168 37.9243 59.5555 33.3169C60.0942 28.7096 59.2225 24.0547 57.04 19.885C54.8575 15.7154 51.4525 12.1995 47.2146 9.73958C42.9768 7.2797 38.0773 5.97527 33.0769 5.97559ZM12.7113 30.6579C12.7113 25.652 14.857 20.8511 18.6763 17.3114C22.4955 13.7718 27.6756 11.7832 33.0769 11.7832C38.4782 11.7832 43.6582 13.7718 47.4775 17.3114C51.2968 20.8511 53.4424 25.652 53.4424 30.6579C53.4424 35.6637 51.2968 40.4646 47.4775 44.0043C43.6582 47.544 38.4782 49.5325 33.0769 49.5325C27.6756 49.5325 22.4955 47.544 18.6763 44.0043C14.857 40.4646 12.7113 35.6637 12.7113 30.6579Z"
        fill="#12A3FD"
      />
      <path
        d="M26.7104 22.1951H34.1497C35.8409 22.1951 37.4628 22.8546 38.6586 24.0286C39.8544 25.2026 40.5262 26.7949 40.5262 28.4552C40.5262 30.1155 39.8544 31.7078 38.6586 32.8818C37.4628 34.0558 35.8409 34.7154 34.1497 34.7154H28.836V40.9756H26.7104V22.1951ZM28.836 24.2818V32.6287H34.1497C35.2772 32.6287 36.3584 32.189 37.1556 31.4063C37.9529 30.6236 38.4007 29.5621 38.4007 28.4552C38.4007 27.3484 37.9529 26.2868 37.1556 25.5042C36.3584 24.7215 35.2772 24.2818 34.1497 24.2818H28.836Z"
        fill="#014E7E"
      />
    </g>
    <defs>
      <clipPath id="clip0_6170_9209">
        <rect width="70" height="70" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
