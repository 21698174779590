export const ProviderIcon = (
  props: Omit<React.ComponentProps<'svg'>, 'children'>
) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M6.66667 10V12.6667C6.66667 13.0333 6.53611 13.3472 6.275 13.6083C6.01389 13.8694 5.7 14 5.33333 14C4.96667 14 4.65278 13.8694 4.39167 13.6083C4.13056 13.3472 4 13.0333 4 12.6667V3.33333C4 2.96667 4.13056 2.65278 4.39167 2.39167C4.65278 2.13056 4.96667 2 5.33333 2H8.66667C9.77778 2 10.7222 2.38889 11.5 3.16667C12.2778 3.94444 12.6667 4.88889 12.6667 6C12.6667 7.11111 12.2778 8.05556 11.5 8.83333C10.7222 9.61111 9.77778 10 8.66667 10H6.66667ZM6.66667 7.33333H8.8C9.16667 7.33333 9.48056 7.20278 9.74167 6.94167C10.0028 6.68056 10.1333 6.36667 10.1333 6C10.1333 5.63333 10.0028 5.31944 9.74167 5.05833C9.48056 4.79722 9.16667 4.66667 8.8 4.66667H6.66667V7.33333Z"
      fill="#12A3FD"
    />
  </svg>
);
