export const BookIcon = (props: Omit<React.ComponentProps<'svg'>, 'children'>) => (
  <svg
    {...props}
    width="82"
    height="81"
    viewBox="0 0 82 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.6667 19C35.9594 19 35.2811 19.295 34.781 19.8201C34.281 20.3452 34 21.0574 34 21.8V44.2C34 44.9426 34.281 45.6548 34.781 46.1799C35.2811 46.705 35.9594 47 36.6667 47H47.3333C48.0406 47 48.7189 46.705 49.219 46.1799C49.7191 45.6548 50 44.9426 50 44.2V21.8C50 21.0574 49.7191 20.3452 49.219 19.8201C48.7189 19.295 48.0406 19 47.3333 19H36.6667ZM44.6667 24.6H39.3333V41.4H44.6667V24.6Z"
      fill="#014E7E"
    />
    <path
      d="M15.6667 19C14.9594 19 14.2811 19.295 13.781 19.8201C13.281 20.3452 13 21.0574 13 21.8C13 22.5426 13.281 23.2548 13.781 23.7799C14.2811 24.305 14.9594 24.6 15.6667 24.6H26.3333C27.0406 24.6 27.7189 24.305 28.219 23.7799C28.719 23.2548 29 22.5426 29 21.8C29 21.0574 28.719 20.3452 28.219 19.8201C27.7189 19.295 27.0406 19 26.3333 19H15.6667ZM15.6667 30.2C14.9594 30.2 14.2811 30.495 13.781 31.0201C13.281 31.5452 13 32.2574 13 33C13 33.7426 13.281 34.4548 13.781 34.9799C14.2811 35.505 14.9594 35.8 15.6667 35.8H26.3333C27.0406 35.8 27.7189 35.505 28.219 34.9799C28.719 34.4548 29 33.7426 29 33C29 32.2574 28.719 31.5452 28.219 31.0201C27.7189 30.495 27.0406 30.2 26.3333 30.2H15.6667ZM13 44.2C13 43.4574 13.281 42.7452 13.781 42.2201C14.2811 41.695 14.9594 41.4 15.6667 41.4H26.3333C27.0406 41.4 27.7189 41.695 28.219 42.2201C28.719 42.7452 29 43.4574 29 44.2C29 44.9426 28.719 45.6548 28.219 46.1799C27.7189 46.705 27.0406 47 26.3333 47H15.6667C14.9594 47 14.2811 46.705 13.781 46.1799C13.281 45.6548 13 44.9426 13 44.2Z"
      fill="#014E7E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7727 8C8.71127 8 6.73425 8.87797 5.27658 10.4408C3.81891 12.0036 3 14.1232 3 16.3333V49.6667C3 51.8768 3.81891 53.9964 5.27658 55.5592C6.73425 57.122 8.71127 58 10.7727 58H52.2273C54.2887 58 56.2658 57.122 57.7234 55.5592C59.1811 53.9964 60 51.8768 60 49.6667V16.3333C60 14.1232 59.1811 12.0036 57.7234 10.4408C56.2658 8.87797 54.2887 8 52.2273 8H10.7727ZM52.2273 13.5556H10.7727C10.0856 13.5556 9.42657 13.8482 8.94068 14.3691C8.45479 14.8901 8.18182 15.5966 8.18182 16.3333V49.6667C8.18182 50.4034 8.45479 51.1099 8.94068 51.6309C9.42657 52.1518 10.0856 52.4444 10.7727 52.4444H52.2273C52.9144 52.4444 53.5734 52.1518 54.0593 51.6309C54.5452 51.1099 54.8182 50.4034 54.8182 49.6667V16.3333C54.8182 15.5966 54.5452 14.8901 54.0593 14.3691C53.5734 13.8482 52.9144 13.5556 52.2273 13.5556Z"
      fill="#08AFF5"
    />
    <rect x="44" y="34" width="38" height="42" rx="5" fill="#E9F1F8" />
    <path
      d="M52.75 41.625C52.392 41.625 52.0486 41.7732 51.7954 42.0369C51.5422 42.3006 51.4 42.6583 51.4 43.0312V48.6562C51.4 49.0292 51.5422 49.3869 51.7954 49.6506C52.0486 49.9143 52.392 50.0625 52.75 50.0625H66.25C66.608 50.0625 66.9514 49.9143 67.2046 49.6506C67.4578 49.3869 67.6 49.0292 67.6 48.6562V43.0312C67.6 42.6583 67.4578 42.3006 67.2046 42.0369C66.9514 41.7732 66.608 41.625 66.25 41.625H52.75ZM51.4 54.2812C51.4 53.9083 51.5422 53.5506 51.7954 53.2869C52.0486 53.0232 52.392 52.875 52.75 52.875H66.25C66.608 52.875 66.9514 53.0232 67.2046 53.2869C67.4578 53.5506 67.6 53.9083 67.6 54.2812V68.3438C67.6 68.7167 67.4578 69.0744 67.2046 69.3381C66.9514 69.6018 66.608 69.75 66.25 69.75H52.75C52.392 69.75 52.0486 69.6018 51.7954 69.3381C51.5422 69.0744 51.4 68.7167 51.4 68.3438V54.2812ZM58.15 72.5625C57.792 72.5625 57.4486 72.7107 57.1954 72.9744C56.9422 73.2381 56.8 73.5958 56.8 73.9688C56.8 74.3417 56.9422 74.6994 57.1954 74.9631C57.4486 75.2268 57.792 75.375 58.15 75.375H60.85C61.208 75.375 61.5514 75.2268 61.8046 74.9631C62.0578 74.6994 62.2 74.3417 62.2 73.9688C62.2 73.5958 62.0578 73.2381 61.8046 72.9744C61.5514 72.7107 61.208 72.5625 60.85 72.5625H58.15ZM51.4 36C49.9678 36 48.5943 36.5926 47.5816 37.6475C46.5689 38.7024 46 40.1332 46 41.625V75.375C46 76.8668 46.5689 78.2976 47.5816 79.3525C48.5943 80.4074 49.9678 81 51.4 81H67.6C69.0322 81 70.4057 80.4074 71.4184 79.3525C72.4311 78.2976 73 76.8668 73 75.375V41.625C73 40.1332 72.4311 38.7024 71.4184 37.6475C70.4057 36.5926 69.0322 36 67.6 36H51.4ZM48.7 41.625C48.7 40.8791 48.9845 40.1637 49.4908 39.6363C49.9972 39.1088 50.6839 38.8125 51.4 38.8125H67.6C68.3161 38.8125 69.0028 39.1088 69.5092 39.6363C70.0155 40.1637 70.3 40.8791 70.3 41.625V75.375C70.3 76.1209 70.0155 76.8363 69.5092 77.3637C69.0028 77.8912 68.3161 78.1875 67.6 78.1875H51.4C50.6839 78.1875 49.9972 77.8912 49.4908 77.3637C48.9845 76.8363 48.7 76.1209 48.7 75.375V41.625Z"
      fill="#014E7E"
    />
  </svg>
);
