export const TopRatedIcon = (
  props: Omit<React.ComponentProps<'svg'>, 'children'>
) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M14.6667 25.3333V21.2C13.5778 20.9556 12.6056 20.4944 11.75 19.8167C10.8944 19.1389 10.2667 18.2889 9.86667 17.2667C8.2 17.0667 6.80556 16.3389 5.68333 15.0833C4.56111 13.8278 4 12.3556 4 10.6667V9.33333C4 8.6 4.26111 7.97222 4.78333 7.45C5.30556 6.92778 5.93333 6.66667 6.66667 6.66667H9.33333C9.33333 5.93333 9.59444 5.30556 10.1167 4.78333C10.6389 4.26111 11.2667 4 12 4H20C20.7333 4 21.3611 4.26111 21.8833 4.78333C22.4056 5.30556 22.6667 5.93333 22.6667 6.66667H25.3333C26.0667 6.66667 26.6944 6.92778 27.2167 7.45C27.7389 7.97222 28 8.6 28 9.33333V10.6667C28 12.3556 27.4389 13.8278 26.3167 15.0833C25.1944 16.3389 23.8 17.0667 22.1333 17.2667C21.7333 18.2889 21.1056 19.1389 20.25 19.8167C19.3944 20.4944 18.4222 20.9556 17.3333 21.2V25.3333H21.3333C21.7111 25.3333 22.0278 25.4611 22.2833 25.7167C22.5389 25.9722 22.6667 26.2889 22.6667 26.6667C22.6667 27.0444 22.5389 27.3611 22.2833 27.6167C22.0278 27.8722 21.7111 28 21.3333 28H10.6667C10.2889 28 9.97222 27.8722 9.71667 27.6167C9.46111 27.3611 9.33333 27.0444 9.33333 26.6667C9.33333 26.2889 9.46111 25.9722 9.71667 25.7167C9.97222 25.4611 10.2889 25.3333 10.6667 25.3333H14.6667ZM9.33333 14.4V9.33333H6.66667V10.6667C6.66667 11.5111 6.91111 12.2722 7.4 12.95C7.88889 13.6278 8.53333 14.1111 9.33333 14.4ZM16 18.6667C17.1111 18.6667 18.0556 18.2778 18.8333 17.5C19.6111 16.7222 20 15.7778 20 14.6667V6.66667H12V14.6667C12 15.7778 12.3889 16.7222 13.1667 17.5C13.9444 18.2778 14.8889 18.6667 16 18.6667ZM22.6667 14.4C23.4667 14.1111 24.1111 13.6278 24.6 12.95C25.0889 12.2722 25.3333 11.5111 25.3333 10.6667V9.33333H22.6667V14.4Z"
      fill="currentColor"
    />
  </svg>
);
