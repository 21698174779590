export const MastercardIcon = (
  props: Omit<React.ComponentProps<'svg'>, 'children'>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="68"
      height="62"
      viewBox="0 0 88 62"
      fill="none"
    >
      <g clipPath="url(#clip0_5827_6881)">
        <path
          d="M87.75 53.9167C87.75 58.5187 84.0187 62.25 79.4167 62.25H8.58333C3.98125 62.25 0.25 58.5187 0.25 53.9167V8.08333C0.25 3.48125 3.98125 -0.25 8.58333 -0.25H79.4167C84.0187 -0.25 87.75 3.48125 87.75 8.08333V53.9167Z"
          fill="#3F51B5"
        />
        <path
          d="M56.5003 10.1667C50.975 10.1667 45.6759 12.3617 41.7689 16.2687C37.8619 20.1757 35.667 25.4747 35.667 31.0001C35.667 36.5254 37.8619 41.8245 41.7689 45.7315C45.6759 49.6385 50.975 51.8334 56.5003 51.8334C62.0257 51.8334 67.3247 49.6385 71.2317 45.7315C75.1387 41.8245 77.3337 36.5254 77.3337 31.0001C77.3337 25.4747 75.1387 20.1757 71.2317 16.2687C67.3247 12.3617 62.0257 10.1667 56.5003 10.1667Z"
          fill="#FFC107"
        />
        <path
          d="M39.8622 43.5001C38.8955 42.2147 38.0643 40.8251 37.4122 39.3334H48.5059C49.0851 38.0084 49.5393 36.6167 49.833 35.1667H36.0872C35.8122 33.8209 35.6663 32.4292 35.6663 31.0001H50.2497C50.2497 29.5709 50.1038 28.1792 49.8309 26.8334H36.0851C36.3809 25.3834 36.833 23.9917 37.4122 22.6667H48.5059C47.8538 21.1751 47.0247 19.7855 46.0559 18.5001H39.8622C40.7726 17.2917 41.7997 16.1626 42.9476 15.1772C39.3059 12.0605 34.5851 10.1667 29.4163 10.1667C17.9101 10.1667 8.58301 19.4938 8.58301 31.0001C8.58301 42.5063 17.9101 51.8334 29.4163 51.8334C36.2268 51.8334 42.2538 48.5522 46.0538 43.5001H39.8622Z"
          fill="#FF3D00"
        />
      </g>
      <defs>
        <clipPath id="clip0_5827_6881">
          <rect width="88" height="62" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
