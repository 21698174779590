'use client';

import * as React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { cn } from '../../../lib/utils';

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> & {
    variant?: 'default' | 'compact';
    maxWidth?: string; // Allow custom width
  }
>(
  (
    {
      className,
      variant = 'default',
      maxWidth = 'max-w-xs',
      side = 'top',
      sideOffset = 6,
      align = 'center',
      ...props
    },
    ref
  ) => (
    <TooltipPrimitive.Content
      ref={ref}
      side={side}
      sideOffset={sideOffset}
      align={align}
      className={cn(
        'z-50 rounded-lg bg-white px-3 py-2 text-sm font-normal text-gray-800 shadow-[0_0_5px_rgb(0,0,0,0.30)] transition-opacity animate-in fade-in data-[state=closed]:animate-out data-[state=closed]:fade-out',
        'data-[side=top]:fade-in data-[side=bottom]:fade-in',
        'data-[side=left]:fade-in data-[side=right]:fade-in',
        variant === 'compact' ? 'px-2 py-1' : '',
        maxWidth, // Dynamic width from prop
        className
      )}
      {...props}
    >
      <TooltipPrimitive.Arrow className="fill-white" />
      {props.children}
    </TooltipPrimitive.Content>
  )
);
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider };
