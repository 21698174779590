export const InfoIcon = (props: Omit<React.ComponentProps<'svg'>, 'children'>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <path
      d="M12.75 7C12.2 7 11.7292 6.80417 11.3375 6.4125C10.9458 6.02083 10.75 5.55 10.75 5C10.75 4.45 10.9458 3.97917 11.3375 3.5875C11.7292 3.19583 12.2 3 12.75 3C13.3 3 13.7708 3.19583 14.1625 3.5875C14.5542 3.97917 14.75 4.45 14.75 5C14.75 5.55 14.5542 6.02083 14.1625 6.4125C13.7708 6.80417 13.3 7 12.75 7ZM12.75 21C12.3333 21 11.9792 20.8542 11.6875 20.5625C11.3958 20.2708 11.25 19.9167 11.25 19.5V10.5C11.25 10.0833 11.3958 9.72917 11.6875 9.4375C11.9792 9.14583 12.3333 9 12.75 9C13.1667 9 13.5208 9.14583 13.8125 9.4375C14.1042 9.72917 14.25 10.0833 14.25 10.5V19.5C14.25 19.9167 14.1042 20.2708 13.8125 20.5625C13.5208 20.8542 13.1667 21 12.75 21Z"
      fill="currentColor"
    />
  </svg>
);
