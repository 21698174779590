export const RelaxIcon = (props: Omit<React.ComponentProps<'svg'>, 'children'>) => (
  <svg
    {...props}
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M51.0729 11.0857C43.529 6.74516 33.0815 7.4165 28.726 14.9344L38.7844 20.7218M51.0729 11.0857C58.6167 15.4263 63.2568 24.7788 58.9012 32.2967L43.8136 23.6155M51.0729 11.0857L52.5247 8.57977M51.0729 11.0857C45.7852 12.4979 42.414 14.4569 38.7844 20.7218M51.0729 11.0857C52.4899 16.3552 52.4724 20.2443 48.8428 26.5092M38.7844 20.7218L43.8136 23.6155M43.8136 23.6155L35.1025 38.6513M8.40002 60.6667L10.9553 57.6109C11.5165 56.939 12.2232 56.4024 13.0222 56.0415C13.8212 55.6806 14.6919 55.5047 15.5689 55.527C16.4459 55.5492 17.3064 55.7691 18.086 56.1701C18.8655 56.5711 19.5438 57.1429 20.07 57.8424C20.5888 58.5324 21.2557 59.0982 22.022 59.4985C22.7883 59.8989 23.6347 60.1236 24.4992 60.1563C25.3638 60.1891 26.2248 60.0289 27.0194 59.6877C27.8139 59.3464 28.522 58.8327 29.0918 58.1839L29.6435 57.553C30.2529 56.8582 31.0044 56.3014 31.8475 55.9199C32.6907 55.5384 33.6059 55.341 34.5319 55.341C35.4579 55.341 36.3731 55.5384 37.2163 55.9199C38.0594 56.3014 38.8109 56.8582 39.4203 57.553L39.9749 58.1839C42.4082 60.9531 46.7812 60.7882 48.9967 57.8453C49.5229 57.1452 50.2013 56.573 50.9811 56.1717C51.7609 55.7704 52.6219 55.5503 53.4992 55.528C54.3766 55.5057 55.2477 55.6818 56.047 56.0431C56.8463 56.4043 57.5531 56.9413 58.1143 57.6138L60.6667 60.6667M19.167 46.1981C23.4066 42.4612 28.8734 40.4023 34.5334 40.4106C40.1933 40.4023 45.6601 42.4612 49.8998 46.1981"
      stroke="#12A3FD"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.2 16.8C14.2928 16.8 16.8 14.2928 16.8 11.2C16.8 8.10721 14.2928 5.60001 11.2 5.60001C8.10718 5.60001 5.59998 8.10721 5.59998 11.2C5.59998 14.2928 8.10718 16.8 11.2 16.8Z"
      stroke="#014E7E"
      strokeWidth="2"
    />
    <path
      d="M11.2 1.86667V2.8M11.2 19.6V20.5333M20.5334 11.2H19.6M2.80003 11.2H1.8667M17.7987 4.60134L17.4328 4.96814M4.96723 17.4328L4.60043 17.7996M17.7987 17.7987L17.4328 17.4319M4.96723 4.9672L4.60043 4.6004"
      stroke="#0283D1"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
