'use client';
import React, { useState } from 'react';
import {
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from './breadcrumb';
import { Icon, IconName } from '@icons/icons';

export type BreadcrumbItemType = {
  icon?: IconName;
  text: string;
  href: string;
};

export interface CustomBreadcrumbProps {
  items: Array<BreadcrumbItemType>;
  variant?: 'default' | 'white';
}

export const CustomBreadcrumb = ({
  items,
  variant = 'default',
}: CustomBreadcrumbProps) => {
  const textColor = variant === 'white' ? 'text-white' : 'text-black';
  const separatorColor = variant === 'white' ? 'light' : 'inherit';

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <Breadcrumb>
      <BreadcrumbList>
        {/* Full Breadcrumbs on Medium+ Screens */}
        <div className="hidden md:flex items-center">
          {items.map((item, index) => (
            <React.Fragment key={item.text}>
              <BreadcrumbItem>
                <BreadcrumbLink
                  href={item.href}
                  className={`flex items-center gap-1 ${textColor} ${
                    index === items.length - 1 ? 'font-medium' : ''
                  }`}
                >
                  {item.icon && (
                    <Icon name={item.icon} size={6} iconColor="inherit" />
                  )}
                  <span>{item.text}</span>
                </BreadcrumbLink>
              </BreadcrumbItem>
              {index < items.length - 1 && (
                <BreadcrumbSeparator variant={separatorColor} />
              )}
            </React.Fragment>
          ))}
        </div>

        {/* Breadcrumbs with Dropdown on Small Screens */}
        <div className="md:hidden flex items-center">
          {/* First Breadcrumb */}
          <BreadcrumbItem>
            <BreadcrumbLink
              href={items[0]?.href}
              className={`flex items-center gap-1 ${textColor}`}
            >
              {items[0]?.icon && (
                <Icon name={items[0].icon} size={6} iconColor="inherit" />
              )}
              <span>{items[0]?.text}</span>
            </BreadcrumbLink>
          </BreadcrumbItem>

          {/* Dropdown for Middle Breadcrumbs */}
          {items.length > 2 && (
            <>
              <BreadcrumbSeparator variant={separatorColor} />
              <div className="relative">
                <button
                  className="focus:outline-none"
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                >
                  <BreadcrumbEllipsis variant={variant} />
                </button>

                {isDropdownOpen && (
                  <div className="absolute left-0 top-8 bg-white border rounded-lg shadow-lg p-2 w-48 z-50">
                    {items.slice(1, items.length - 1).map((item, index) => (
                      <BreadcrumbItem
                        key={item.text}
                        className="block px-3 py-2 hover:bg-gray-100"
                      >
                        <BreadcrumbLink
                          href={item.href}
                          className="flex items-center gap-1 text-black"
                        >
                          {item.icon && (
                            <Icon name={item.icon} size={5} iconColor="inherit" />
                          )}
                          <span>{item.text}</span>
                        </BreadcrumbLink>
                      </BreadcrumbItem>
                    ))}
                  </div>
                )}
              </div>
            </>
          )}

          {/* Last Breadcrumb */}
          {items.length > 1 && (
            <>
              <BreadcrumbSeparator variant={separatorColor} />
              <BreadcrumbItem>
                <BreadcrumbLink
                  href={items[items.length - 1]?.href}
                  className={`flex items-center gap-1 font-medium ${textColor}`}
                >
                  {items[items.length - 1]?.icon && (
                    <Icon
                      name={items[items.length - 1]?.icon as IconName}
                      size={6}
                      iconColor="inherit"
                    />
                  )}
                  <span>{items[items.length - 1]?.text}</span>
                </BreadcrumbLink>
              </BreadcrumbItem>
            </>
          )}
        </div>
      </BreadcrumbList>
    </Breadcrumb>
  );
};
