export const OverviewIcon = (
  props: Omit<React.ComponentProps<'svg'>, 'children'>
) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M9 19.0125H9.0125V19V5V4.9875H9H5H4.9875V5V19V19.0125H5H9ZM14.9875 9V9.0125H15H19H19.0125V9V5V4.9875H19H15H14.9875V5V9ZM14.9875 19V19.0125H15H19H19.0125V19V15V14.9875H19H15H14.9875V15V19ZM10.9875 20.9875H5C4.45334 20.9875 3.98568 20.793 3.59634 20.4037C3.207 20.0143 3.0125 19.5467 3.0125 19V5C3.0125 4.45334 3.207 3.98568 3.59634 3.59634C3.98568 3.207 4.45334 3.0125 5 3.0125H10.9875V20.9875ZM13.0125 10.9875V3.0125H19C19.5467 3.0125 20.0143 3.207 20.4037 3.59634C20.793 3.98568 20.9875 4.45334 20.9875 5V10.9875H13.0125ZM13.0125 20.9875V13.0125H20.9875V19C20.9875 19.5467 20.793 20.0143 20.4037 20.4037C20.0143 20.793 19.5467 20.9875 19 20.9875H13.0125Z"
      fill="currentColor"
      stroke="white"
      strokeWidth="0.025"
    />
  </svg>
);
