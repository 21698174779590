export const CardRedirectIcon = (
  props: Omit<React.ComponentProps<'svg'>, 'children'>
) => (
  <svg
    {...props}
    width="24"
    height="16"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2C0 0.89543 0.89543 0 2 0H17C18.1045 0 19 0.89543 19 2V6C19 6.27615 18.7761 6.5 18.5 6.5C18.2239 6.5 18 6.27615 18 6V4C18 3.4477 17.5523 3 17 3H2C1.44772 3 1 3.4477 1 4V14C1 14.5523 1.44772 15 2 15H17C17.5523 15 18 14.5523 18 14V11C18 10.7239 18.2239 10.5 18.5 10.5C18.7761 10.5 19 10.7239 19 11V14C19 15.1046 18.1045 16 17 16H2C0.89543 16 0 15.1046 0 14V2ZM2 2C2.27614 2 2.5 1.77614 2.5 1.5C2.5 1.22386 2.27614 1 2 1C1.72386 1 1.5 1.22386 1.5 1.5C1.5 1.77614 1.72386 2 2 2ZM3.5 2C3.77614 2 4 1.77614 4 1.5C4 1.22386 3.77614 1 3.5 1C3.22386 1 3 1.22386 3 1.5C3 1.77614 3.22386 2 3.5 2ZM5.5 1.5C5.5 1.77614 5.27615 2 5 2C4.72386 2 4.5 1.77614 4.5 1.5C4.5 1.22386 4.72386 1 5 1C5.27615 1 5.5 1.22386 5.5 1.5ZM20.496 6.2043L22.2916 8H14.5C14.2239 8 14 8.22385 14 8.5C14 8.7761 14.2239 9 14.5 9H22.2916L20.496 10.7956C20.3005 10.9911 20.3005 11.308 20.496 11.5034C20.6914 11.6988 21.0082 11.6988 21.2037 11.5034L23.8535 8.85355C24.0488 8.65825 24.0488 8.3417 23.8535 8.1464L21.2037 5.49655C21.0082 5.3011 20.6914 5.3011 20.496 5.49655C20.3005 5.692 20.3005 6.0089 20.496 6.2043Z"
      fill="#0570DE"
    />
  </svg>
);
