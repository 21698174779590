export const VisaIcon = (props: Omit<React.ComponentProps<'svg'>, 'children'>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="68"
      height="48"
      viewBox="0 0 68 48"
      fill="none"
    >
      <g clipPath="url(#clip0_6291_64491)">
        <path
          d="M67.807 41.7419C67.807 45.3048 64.9238 48.1936 61.3676 48.1936H6.63275C3.0766 48.1936 0.193359 45.3048 0.193359 41.7419V6.25807C0.193359 2.69517 3.0766 -0.193542 6.63275 -0.193542H61.3676C64.9238 -0.193542 67.807 2.69517 67.807 6.25807V41.7419Z"
          fill="#1565C0"
        />
        <path
          d="M19.8104 15.9355L15.5829 28.5678C15.5829 28.5678 14.5091 23.2242 14.4029 22.5533C11.9961 17.0516 8.44482 17.3581 8.44482 17.3581L12.6304 33.6774V33.6742H17.7192L24.7558 15.9355H19.8104ZM23.8398 33.6774H28.4617L31.2564 15.9355H26.5765L23.8398 33.6774ZM56.5503 15.9355H51.687L44.1046 33.6774H48.6959L49.6425 31.1436H55.4315L55.9241 33.6774H60.1306L56.5503 15.9355ZM50.9239 27.7549L53.4401 21.05L54.7569 27.7549H50.9239ZM37.8133 21.1065C37.8133 20.1291 38.615 19.4016 40.9139 19.4016C42.4078 19.4016 44.1191 20.4887 44.1191 20.4887L44.8693 16.7645C44.8693 16.7645 42.6831 15.9339 40.5372 15.9339C35.677 15.9339 33.1705 18.2629 33.1705 21.2113C33.1705 26.5436 39.5761 25.8129 39.5761 28.5516C39.5761 29.021 39.2042 30.1065 36.5367 30.1065C33.8611 30.1065 32.0951 29.1242 32.0951 29.1242L31.2982 32.6984C31.2982 32.6984 33.0095 33.6758 36.3161 33.6758C39.6308 33.6758 44.2285 31.192 44.2285 27.6242C44.2285 23.3323 37.8133 23.0226 37.8133 21.1065Z"
          fill="white"
        />
        <path
          d="M15.0232 25.5242L13.4681 17.8662C13.4681 17.8662 12.7646 16.2065 10.9358 16.2065C9.10703 16.2065 3.78809 16.2065 3.78809 16.2065C3.78809 16.2065 12.9014 18.9033 15.0232 25.5242Z"
          fill="#FFC107"
        />
      </g>
      <defs>
        <clipPath id="clip0_6291_64491">
          <rect width="68" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
